<template>
  <div>
    <button class="btn" @click="fullScreen">
      {{isFullscreen?'退出全屏':'进入全屏'}}
    </button>
    <audio ref="audioPlay" src="@/assets/audio/game4/confirm.mp3"></audio>
    <audio ref="instruction" src="@/assets/audio/game3/instruction.mp3"></audio>
    <div class="game-con">
      <img class="game-bg" :src="backgroundPic" alt="" />
      <div class="game-top">
        <h3 class="game-tip">{{ gameTitle }}</h3>
        <img class="instruction-pic" src="@/assets/img/game3/instruction.png" alt="" @click="showMask">
      </div>
      <div class="game-wrap">
        <div class="game-item" v-for="(item, i) in lists" :key="item.id">
          <div class="ques-wrap" :class="{ 'slide-left': item.status == 2 }">
            <p class="ques" v-if="!isConfig">{{ item.ques }}</p>
            <p class="ques" v-else v-html="item.ques"></p>
          </div>
          <div class="ques-num" @click="changeStatus(item)">
            <img :src="pics[i]" alt="" />
          </div>
          <div class="ans-wrap" :class="{ 'slide-left': item.status == 3 }">
            <p class="ques" v-if="!isConfig">{{ item.ans }}</p>
            <p class="ques" v-else v-html="item.ans"></p>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗begin -->
    <transition name="fade">
      <div class="mask-container" v-if="isShowMask">
        <div class="mask-bg" @click="hideMask"></div>
        <div
          class="mask-con"
          :style="{
            background:
              'url(' + instructionPic + ')center center no-repeat',
          }"
        ></div>
      </div>
    </transition>
    <!-- 弹窗end -->
  </div>
</template>
<script>
import screenfull from "screenfull";
export default {
  data() {
    return {
      preSrc: config.src,
      isFullscreen: false,
      instructionPic: "",
      backgroundPic: "",
      lists: [],
      isConfig: false,
      isShowMask: false,
      gameTitle: "说明：此游戏适用连词成句，改错，汉译英，英译汉",
      pics: [
        require("@/assets/img/game4/img01.png"),
        require("@/assets/img/game4/img02.png"),
        require("@/assets/img/game4/img03.png"),
        require("@/assets/img/game4/img04.png"),
        require("@/assets/img/game4/img05.png"),
        require("@/assets/img/game4/img06.png"),
        require("@/assets/img/game4/img07.png"),
        require("@/assets/img/game4/img08.png"),
        require("@/assets/img/game4/img09.png"),
        require("@/assets/img/game4/img10.png"),
      ],
    };
  },
  created() {
    let _self = this;
    let configId = _self.$route.params.configId;
    let gameId = _self.$route.params.gameId;
    let title = _self.$route.meta.title;
    document.title = title? title: ''
    if (configId) {
      _self.initData(configId);
      return;
    } else {
      _self.getInfo(gameId);
    }
  },
  methods: {
    // 全屏
    fullScreen() {
      this.isFullscreen = !this.isFullscreen;
      // 如果不允许进入全屏，发出不允许提示
      if (!screenfull.isEnabled) {
        this.$message({
          message: "您的浏览器不支持全屏",
          type: "warning",
        });
        return false;
      }
      screenfull.toggle();
    },
    // 获取游戏详情
    getInfo(id) {
      let _self = this;
      _self.axios
        .post(config.url.gameInfo, _self.$qs.stringify({ game_id: id }))
        .then((res) => {
          if (res.code == 200) {
            _self.backgroundPic = config.src + res.data.background;
            _self.instructionPic = config.src + res.data.instruction;
            _self.gameTitle = res.data.config
              ? res.data.config
              : "说明：此游戏适用连词成句，改错，汉译英，英译汉";
            res.data.pic.forEach((item) => {
              item.status = 1;
            });
            _self.lists = res.data.pic;
          }
        });
    },
    initData(id) {
      let _self = this;
      _self.axios
        .post(config.url.gamePics, _self.$qs.stringify({ config_id: id }))
        .then((res) => {
          if (res.code == 200) {
            _self.backgroundPic = config.src + res.data.background;
            _self.instructionPic = config.src + res.data.instruction;
            _self.gameTitle = res.data.title
              ? res.data.title
              : res.data.game_title;
            if (res.data.config.length <= 0) {
              return;
            }
            _self.isConfig = true;
            res.data.config.forEach((item) => {
              item.status = 1;
            });
            _self.lists = res.data.config;
          }
        });
    },
    //展示弹窗
    showMask() {
      let _self = this;
      _self.$refs.instruction.currentTime = 0;
      _self.$refs.instruction.play();
      _self.isShowMask = true;
    },
    // 隐藏弹窗
    hideMask() {
      let _self = this;
      _self.$refs.instruction.currentTime = 0;
      _self.$refs.instruction.play();
      _self.isShowMask = false;
    },
    // 改变状态
    changeStatus(item) {
      let _self = this;
      if (item.status == 1) {
        item.status = 2;
      } else {
        item.status = item.status == 2 ? 3 : 2;
      }
      _self.$refs.audioPlay.currentTime = 0;
      _self.$refs.audioPlay.play();
    },
  },
  mounted() {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => {
        this.isFullscreen = screenfull.isFullscreen;
      });
    }
  },
};
</script>
<style scoped>
.line {
  width: 100%;
  height: 60px;
}
.btn {
  margin: 60px auto 0;
}
.game-con {
  width: 1280px;
  height: 720px;
  max-width: 1280px;
  margin: 30px auto 0;
  padding: 20px;
  border: 4px solid #182477;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.game-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.game-top{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.instruction-pic{
  position: absolute;
  right: 30px;
  top: 30px;
  width: 136px;
  cursor: pointer;
}
.game-tip {
  font-size: 24px;
  min-width: 536px;
  height: 80px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #182447;
  background: #75c8d0;
  color: #090a58;
  border-radius: 70px 70px 20px 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.game-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.game-item {
  width: 45%;
  display: flex;
  align-items: center;
  margin: 8px 0;
}
.ques-wrap {
  width: 0;
  transition: all 0.5s;
  overflow: hidden;
}
.ans-wrap {
  width: 0;
  overflow: hidden;
  transition: all 0.5s;
}
.ques-wrap p {
  width: 526px;
  padding: 12px 80px 12px 30px;
  box-sizing: border-box;
  border: 2px solid #182447;
  background: #90D3EF;
  color: #182447;
  border-radius: 20px;
  font-size: 20px;
  text-align: left;
  height: 70px;
  display: flex;
  align-items: center;
}
.ans-wrap p {
  width: 526px;
  padding: 0 30px 0 80px;
  box-sizing: border-box;
  border: 2px solid #182447;
  background: #F0C97B;
  color: #182447;
  border-radius: 20px;
  font-size: 20px;
  text-align: left;
  height: 70px;
  display: flex;
  align-items: center;
}
.slide-left {
  width: 526px;
}
.ques-wrap.slide-left{
  margin-right: -80px;
}
.ans-wrap.slide-left{
  margin-left: -80px;
}
.ques-num {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}
.ques-num img {
  width: 100%;
  height: 100%;
}
.mask-con {
  width: 738px;
  height: 542px;
  background-size: 100%;
  padding: 150px 80px 55px;
  box-sizing: border-box;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.mask-con .title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 28px;
}
.mask-con p {
  font-size: 24px;
  line-height: 42px;
  text-align: left;
}
</style>
